// Here you can add other styles

.osrit-bc-scheduled {
  background-color: #007bff;
}

.osrit-bc-delivered {
  background-color: #28a745;
}

.osrit-bc-refused {
  background-color: #dc3545;
}

.osrit-bc-paid {
  background-color: #28a745;
}

.osrit-bc-invoicePending {
  background-color: #ffc107;
}

.osrit-bc-inTransit {
  background-color: #ffc107;
}

.osrit-txt-scheduled {
  color: red;
}
// Date InputDatePicker

div.react-datepicker-popper {
  z-index: 9;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react_osrit_date_picker {
  width: 100%;
  border: 1px solid #e3e7ea;
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;

  &.error {
    border: 1px solid #f86c6b;
  }
}

.react_osrit_date_picker {
  width: 100%;
  border: 1px solid #e3e7ea;
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;

  &.error {
    border: 1px solid #f86c6b;
  }
}

.osrit_dispatch_data {
  &.row {
    margin-top: 0;
    margin-bottom: 2px;
  }

  &.small {
    margin-bottom: 0;

    dd,
    dt {
      font-size: 10px;
    }
  }

  dt {
    margin-bottom: 0.1rem;
    padding-right: 0;
  }

  dd {
    margin-bottom: 0.1rem;
    font-size: 12px;
    padding-right: 0;
  }
}

.osrit_more_button {
  font-size: 10px;
  color: #2196F3;
  cursor: pointer;
  margin-left: 5px;
}

.osrit_checkbox {
  label {
    font-size: 15px;
    cursor: pointer;
  }
  /*** custom checkboxes ***/
  input[type=checkbox] {
    display: none;
  }
  /* to hide the checkbox itself */
  input[type=checkbox]+label:before {
    font-family: FontAwesome;
    display: inline-block;
  }

  input[type=checkbox]+label:before {
    content: "\f096";
  }

  input[type=checkbox]:disabled+label:before {
    content: "\f0c8";
    color: #ccc;
  }
  /* unchecked icon */
  input[type=checkbox]+label:before {
    letter-spacing: 10px;
  }
  /* space between checkbox and label */
  input[type=checkbox]:checked+label:before {
    content: "\f046";
  }
  /* checked icon */
  input[type=checkbox]:checked+label:before {
    letter-spacing: 5px;
    color: #21a8d8;
  }
}

.rc-steps.rc-steps-horizontal.rc-steps-label-vertical {
  .rc-steps-item-container {
    .rc-steps-item-tail {
      margin-left: 65px!important;
      width: 95%;
    }

    .rc-steps-item-icon {
      min-height: 40px;
    }

    .rc-steps-item-content {
      margin-left: 15px;
    }
  }

  .item_not_selected {
    .rc-steps-item-title {
      color: #ccc;
    }
  }

  .item_selected {
    .rc-steps-item-title {
      color: black;
      font-weight: 800;
      font-size: 20px;
    }
  }
}

.osrit_breadcrumb {
  .breadcrumb {
    margin-bottom: 10px;
  }
}

.leaflet-marker-icon {
  border: none !important;
  background: #0000 !important;
}

.react-datepicker-popper {
  z-index: 1000 !important;
}

.sr-only {
  display: none;
}

.leaflet-marker-icon img {
  width: 100%;
  height: 100%;
}

.dashboard_dt_letter {
  color: #0085e3;
  font-size: 32px;
  font-family: 'Audiowide';

  font-weight:bolder .text_small {
    font-size: 12px;
  }
}

.carousel-control-next {
  top: 30% !important;
  bottom: 30% !important;
}

.carousel-control-prev {
  top: 30% !important;
  bottom: 30% !important;
}

.custom-date{
  font-weight: 600;
  font-size: 80%;

}
